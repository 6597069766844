<template>
    <div class="table-container">
        <table class="table is-fullwidth">
            <thead>
            <tr>
                <th>{{ i18n('Id') }}</th>
                <th>{{ i18n('Detail') }}</th>
                <th>{{ i18n('Goal') }}</th>
                <th>{{ i18n('Points') }}</th>
                <th>{{ i18n('User') }}</th>
                <th>{{ i18n('Actions') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="liquidation in liquidations" :key="liquidation.id">
                <td>{{ liquidation.id }}</td>
                <td>{{ liquidation.detail }}</td>
                <td>{{ liquidation.goal }}</td>
                <td>{{ liquidation.points }}</td>
                <td>{{ liquidation.person.name }}</td>
                <td class="actions">
                    <div class="button-container">
                        <button
                            class="button is-primary"
                            @click="approve(liquidation)"
                            :disabled="liquidation.approved">
                            {{ liquidation.approved ? i18n('Approved') : i18n('Approve') }}
                        </button>
                        <button
                            v-if="(liquidation.person.user.role_id === 1 ||
                            liquidation.person.user.role_id === 3) &&
                            (liquidation.approved) &&
                            (!hasReplicateRule(liquidation.variable.id))"
                            :class="[liquidation.replicated ?
                             'button is-success' : 'button is-danger']"
                            @click="replicate(liquidation)"
                            :disabled="liquidation.replicated">
                            {{ liquidation.replicated ? i18n('Replicated') : i18n('Replicate') }}
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css';

export default {
    name: 'Approves',
    inject: ['route', 'http', 'i18n', 'errorHandler', 'toastr'],
    data() {
        return {
            liquidations: [],
            rules: [],
            variableIds: [],
        };
    },
    mounted() {
        this.fetchLiquidations();
        this.fetchRules();
    },
    methods: {
        hasReplicateRule(variableId) {
            const matchingRule = this.rules.find(rule => rule.variable_id
                === variableId && rule.replicate === true);
            return !!matchingRule;
        },
        fetchRules() {
            this.http
                .get(this.route('rule.options'))
                .then(({ data }) => {
                    this.rules = data;
                    this.variableIds = this.rules.map(rule => rule.variable_id);
                })
                .catch(this.errorHandler);
        },
        fetchLiquidations() {
            this.http
                .get(this.route('liquidation.approves', { liquidation: this.$route.params.liquidation, period: this.$route.params.period }))
                .then(({ data }) => {
                    this.liquidations = data.liquidations;
                })
                .catch(this.errorHandler);
        },
        approve(liquidation) {
            this.http
                .patch(this.route('liquidation.approveAction', { liquidation: liquidation.id }))
                .then(data => {
                    this.fetchLiquidations();
                    if (data.data.error) {
                        this.toastr.error(data.data.message);
                    } else if (data.data.message) {
                        this.toastr.success(data.data.message);
                    }
                })
                .catch(this.errorHandler);
        },
        replicate(liquidation) {
            this.http
                .post(this.route('liquidation.replicate', { liquidation: liquidation.id }))
                .then(data => {
                    this.fetchLiquidations();
                    if (data.data.error) {
                        this.toastr.error(data.data.message);
                    } else if (data.data.message) {
                        this.toastr.success(data.data.message);
                    }
                })
                .catch(this.errorHandler);
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../../sass/enso.scss';
.actions button {
    margin: auto;
}
.actions {
    display: flex;
    min-height: 3.375rem;
}
.mobile-details {
    display: none;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    max-height: 0;
}
.card {
    margin: auto;
}
.card-content{
    padding: .6rem;
}
.button.is-primary{
    margin: auto;
}
button {
    margin-top: 10px;
    background-color: $ensoGreen;
    color: $secondary;
    border-radius: 4px;
    border: none;
    margin-bottom: 10px;
    cursor: pointer;
}

.label {
    font-weight: bold;
    margin-bottom: 5px;
}
.button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: .6rem;
}
@media (max-width: 768px) {
    .table-container {
        overflow-x: auto;
    }

    table.is-fullwidth {
        font-size: 12px;
    }

    table.is-fullwidth th,
    table.is-fullwidth td {
        padding: 0.5rem;
    }
    button{
        font-size: .7rem;
    }
    .button-container{
        flex-direction: column;
    }
}
</style>
